<template>
  <section>
    <div class="mt-16">
      <h2 class="text-center text-body-4 text-4xl mb-4">Platba úspěšná</h2>
    </div>

    <div class="max-w-form mt-10 mx-auto mb-3 px-4">
      Platba proběhla úspěšně.
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
